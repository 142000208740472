export const styles = {
  root: (theme) => ({
    color: 'kit.text.main',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  }),
  header: (theme) => ({
    marginTop: '40px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '32px',
    },
  }),
  subheader: (theme) => ({
    marginBottom: '24px',
    textAlign: 'center',
  }),
  date: {
    marginRight: '32px',
  },
  dateBox: (theme) => ({
    marginBottom: '32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '291px',
    },
  }),
  button: {
    marginBottom: '42px',
  },
  parsedText: (theme) => ({
    '& > div': {
      marginBottom: '24px',
      fontSize: '16px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    '& > .MuiTypography-desktopH1': {
      fontSize: '46px',
      [theme.breakpoints.down('md')]: {
        fontSize: '34px',
      },
    },
    '& > .MuiTypography-desktopH2': {
      marginTop: '42px',
      marginBottom: '24px',
      fontSize: '34px',
      [theme.breakpoints.down('md')]: {
        fontSize: '22px',
      },
    },
    '& > .MuiTypography-desktopH3': {
      fontSize: '18px',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
    },
    '& > a': {
      fontSize: '16px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  }),
  oldVersionBlock: (theme) => ({
    backgroundColor: 'kit.background.lightpurple',
    marginBottom: '56px',
    minHeight: '88px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 60px',
    '& > a:not(:last-child)': {
      marginBottom: '16px',
    },
    '& > a': {
      fontSize: '16px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '72px',
      padding: '8px 16px',
      marginBottom: '40px',
    },
  }),
};
