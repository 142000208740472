export default {
  seo: {
    title:
      'Оферта на заключение договора об оказании платных образовательных услуг: основные положения Эльбрус Буткемп',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Здесь вы найдете все необходимые договоры и соглашения для обучения в Эльбрус Буткемп. Мы ценим прозрачность и открытость в наших отношениях с клиентами.',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Оферта на заключение договора об оказании платных образовательных услуг: основные положения Эльбрус Буткемп',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Здесь вы найдете все необходимые договоры и соглашения для обучения в Эльбрус Буткемп. Мы ценим прозрачность и открытость в наших отношениях с клиентами.',
      },
      {
        property: 'og:image',
        name: null,
        content:
          'https://elbrusboot.camp/static/70d53c51ea8b2df72e0ea27629da3a5d/74209/slide-5.jpg',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: '@elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/70d53c51ea8b2df72e0ea27629da3a5d/74209/slide-5.jpg',
      },
    ],
  },
};
