import React from 'react';
import { Link, Button } from 'gatsby-theme-material-ui';
import { format } from 'date-fns';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Markdown from '../Markdown/Markdown';
import Section from '../Section/Section';
import { styles } from './styles';

function Contract({ contractAgreements, bg }) {
  const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const formattedDate = format(
    new Date(contractAgreements?.date),
    'dd.MM.yyyy',
  );
  return (
    <>
      <Section bg={bg}>
        <Box sx={styles.root}>
          <Typography
            variant={isSm ? 'mobileH2' : 'desktopH2'}
            sx={styles.header}
          >
            Оферта
          </Typography>
          <Typography
            variant={isSm ? 'mobileH3' : 'desktopH3'}
            sx={styles.subheader}
          >
            на заключение договора об оказании платных образовательных услуг
          </Typography>
          <Box sx={styles.dateBox}>
            <Typography
              variant={isSm ? 'mobileP2' : 'desktopP2'}
              sx={styles.date}
            >
              №7 от {formattedDate}
            </Typography>
            <Typography variant={isSm ? 'mobileP2' : 'desktopP2'}>
              г. Москва
            </Typography>
          </Box>
          <Link underline="none" href="/docs/contract31012024.pdf" download>
            <Button
              variant="kitPrimary"
              size={isSm ? 'small' : 'large'}
              component="span"
              sx={styles.button}
            >
              СКАЧАТЬ ДОГОВОР
            </Button>
          </Link>
          <Box sx={styles.parsedText}>
            <Markdown variant="desktopP2">{contractAgreements.text}</Markdown>
          </Box>
        </Box>
      </Section>
      <Box sx={styles.oldVersionBlock}>
        <Link
          variant="kitPrimary"
          href="/docs/contract_before_30_01_2024.pdf"
          download
        >
          Оферта (договор) до 30.01.2024г.
        </Link>
        <Link
          variant="kitPrimary"
          href="/docs/contract_before_13_11_2023.pdf"
          download
        >
          Оферта (договор) до 13.11.2023г.
        </Link>
        <Link
          variant="kitPrimary"
          href="/docs/contract_before_21_02_2023.pdf"
          download
        >
          Оферта (договор) до 21.02.2023г.
        </Link>
        <Link
          variant="kitPrimary"
          href="/docs/contract_before_14_10_2022.pdf"
          download
        >
          Оферта (договор) до 14.10.2022г.
        </Link>
        <Link
          variant="kitPrimary"
          href="/docs/contract_before_19_08_2022.pdf"
          download
        >
          Оферта (договор) до 19.08.2022г.
        </Link>
        <Link
          variant="kitPrimary"
          href="/docs/offer_information_and_consulting_services_Elbrus_Bootcamp_before_05.04.2022.pdf"
          download
        >
          Оферта на инф-конс. услуги до 05.04.2022г.
        </Link>
      </Box>
    </>
  );
}

export default Contract;
