import React from 'react';
import Layout from '../shared/Layout/Layout';
import { useStrapiRequest } from '../customHooks/useStrapiRequest';
import Contract from '../shared/Contract/Contract';
import data from '../pages-fsd/ContractAgreementsPage/model/contract_agreements.data';
import HeadMaker from '../HeadMaker';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

function ContractAgreementsPage() {
  const data = useStrapiRequest('agreements/2');
  const contractAgreements = data[0];
  return (
    <Layout leadPage="Договор оферты">
      {contractAgreements && (
        <Contract contractAgreements={contractAgreements} bg="contract" />
      )}
    </Layout>
  );
}

export default ContractAgreementsPage;
